<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="group">
      <v-card-title class="headline">Permissions for {{group.name}}</v-card-title>
      <v-card-text>      
        <p>
          By default only event (co-)admins have access to reports. You can optionally grant reporting access for a team to others. 
        </p>
        <p>
          Persons with this permission will be able to download Excel reports for all leaderboards within this event, but only have access to data of members of this team.
        </p>
        <p v-if="reportPageUrl">
          <div>Share this url with the group admins so they can download the reports for this group:</div>
          <v-text-field readonly v-model="reportPageUrl" >
            <template v-slot:append>
              <v-btn text @click="$helpers.copyToClipboard(reportPageUrl, $toast);showQrCode=false;">Copy</v-btn>
            </template>            
          </v-text-field>
        </p>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="permissions"
        sort-by="name"
        :items-per-page="25"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Permissions</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="accent" class="mb-2" :disabled="!eventUtil.isProPlan()" @click="showAddUserDialog=true">Add</v-btn>
          </v-toolbar>
        </template>
          <template v-slot:item.roles="{ item }">
            {{ item.roles.map(x => $helpers.displayText(siteData.roles, x)).join(", ") }}
          </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-4" @click="editPermissions(item)">
            fa-pencil
          </v-icon>
          <v-icon small class="mr-4" @click="deletePermissions(item.name, item.id)">
            fa-trash
          </v-icon>
        </template>
        <template v-slot:no-data>
          <i>No extra permissions granted, only you will have access to this organization.</i>
        </template>
      </v-data-table>
    </v-card>


    <v-dialog v-model="showAddUserDialog">
      <v-card>
        <v-card-title>Add an admin</v-card-title>
        <v-card-text>
          Add a reporting user to this group. This user will have access to reporting data of all users of this group.
        </v-card-text>
        <v-card-text>
          You need to have the Profile ID of the user you want to add. The user can find this (and send to you) from the Edit Profile screen in the app or website when they are logged in.
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="profileId" 
            label="Profile ID"
            persistent-hint
            hint="The user can find this on their Edit Profile screen"
            required
            autofocus
            />
        </v-card-text>
        
        <v-card-text>
          <v-btn color="primary" :disabled="!profileId" @click="addPermissions">Save</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>    
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import groupService from "@/services/groupService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "TeamPermissions",
  components: {
  },
  props: {
    event: Object,
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      group: null,
      showAddUserDialog: false,
      permissions: [],
      profileId: null,
      selectedRoles: ["REPORTING"],
      headers: [
        { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name',},
        { text: 'Roles', align: 'start', sortable: true, filterable: true, value: 'roles',},
        { text: 'Actions', align: 'start', sortable: false, filterable: false, value: 'actions',},
      ],
    };
  },
  async mounted() {
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {
    async getGroup(id) {
      this.group = (await groupService.get(id)).data;
      this.permissions = (await eventManagerService.getTeamPermissions(this.$route.params.id, id)).data.data || [];
    },

    async addPermissions() {
      if (this.profileId && this.selectedRoles && this.selectedRoles.length) {
        const response = (await eventManagerService.addTeamPermissions(this.$route.params.id, this.group.id, this.profileId, this.selectedRoles)).data;
        await this.getData();
        this.showAddUserDialog = false;
        this.$helpers.toastResponse(this, response, 'Permission added successfully.');
      }
    },

    async editPermissions(permissions) {
      this.profileId = permissions.id;
      this.selectedRoles = permissions.roles;
      this.showAddUserDialog = true;
    },

    async deletePermissions(name, profileId) {
      if (confirm(`Are you sure you want to remove access by ${name}?`)) {
        const response = (await eventManagerService.deleteTeamPermissions(this.event.id, this.group.id, profileId)).data;
        await this.getData();
        this.$helpers.toastResponse(this, response, 'Permission revoked successfully.');
      }
    },

    async getData() {
      if (this.user) {
        await this.getGroup(this.$route.params.groupId);
      }
    },

  },
  computed: {
    reportPageUrl() {
      return this.event && this.group && tenant.homeUrl.slice(0,-1) + this.$router.resolve({name: 'profileGroupsEventReports', params: {id: this.group.id, eventId : this.event.id}}).href;
    },
    
    breadcrumbItems() {
      return !this.event || !this.group ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Teams', exact: true, to: { name: 'eventmanagerTeams', params: {id: this.event.id}} },
        { text: this.group.name, disabled: true },
        { text: 'Permissions', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event);
    },
    ...mapGetters({
      user: "user"
    })
  },
  
};
</script>

